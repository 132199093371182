import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root : {
        minWidth: 200 ,
        textAlign : 'left'
    },
    errorMessage : {
        color : theme.palette.error.main
    },
    placeholder : {
        color : theme.palette.primary.main,
        marginLeft: `${theme.spacing(2)}px`
    },
    textareaControl : {
        width : "100%",
        margin: `${theme.spacing(2)}px`
    }
}))

export default useStyles;