import React, { Suspense, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AuthRoute, PrivateRoute } from '../Components/CustomRoutes';
import Layout from "../Components/Layout";
import Loading from '../Components/Loading';
import { USER_INITIAL_STATE, useUserContext } from '../Services/Providers';
import { StorageKeys } from '../Configs/Storage';
import AccountActions from '../Redux/Actions/Account';
import { authRoutes, privateRoutes, publicRoutes } from "./RouteList";
import { getStorage } from '../Services/Helpers';
import { useDispatch } from 'react-redux'
import api from '../Services/Api'


export default function Routes() {
  const { userContextObj, setUserContext } = useUserContext();
  const dispatch = useDispatch();
  const [IsLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let token = getStorage(StorageKeys.token);
    let user = JSON.parse(window.localStorage.getItem(StorageKeys.userContext));
    async function fetchUser() {
      setIsLoading(true)
      if (!token || !user) {
        dispatch(AccountActions.logout());
        setUserContext({ ...USER_INITIAL_STATE });
      } else {
        //TODO handle the fail response
        const res = await api.account.get({}, { headers: { Authorization: "Bearer " + token, } });
        console.log("res", res)
        const data = !!res.data && res.ok ? res.data : user.data
        setUserContext({ isAuthenticated: true, token, data });
      }

      setIsLoading(false);
    }
    fetchUser()
  }, [])


  if (IsLoading) { return <Loading /> }

  return (
    <Suspense fallback={<Loading />}>

      <Switch>
        {
          authRoutes.map((RouteObj, index) => (
            <AuthRoute key={index} {...RouteObj} />
          ))
        }
        {
          publicRoutes.map((RouteObj, index) => (
            <Route key={index} {...RouteObj} />
          ))
        }
        <Layout>
          <Switch>
            {privateRoutes.map((RouteObj, index) => {
              return (
                <PrivateRoute key={index} {...RouteObj} />
              )
            })}
          </Switch>
        </Layout>

      </Switch>
      {/* <Redirect to="/profile" exact /> */}
    </Suspense>
  );
}
