import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { StorageKeys } from '~/Configs/Storage';
import { getStorage } from '~/Services/Helpers';

function AuthRoute(props) {
    return (
        getStorage(StorageKeys.token)
            ? <Redirect to="/profile" exact />
            : <Route {...props} /> 
    )
}


export default AuthRoute;