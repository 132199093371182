const openAside = 275;
const closedAside = 110;

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: "100vh",
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    direction: theme.direction,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    backgroundColor: theme.palette.type === "light" ? "#fff" : "#212121",
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingLeft: theme.direction === "ltr" ? closedAside + 35 : '',
    paddingRight: theme.direction === "rtl" ? closedAside + 35 : '',

    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2)
    }
  },
  drawerOpenContent: {
    paddingLeft: theme.direction === "ltr" ? openAside + 35 : "",
    paddingRight: theme.direction === "rtl" ? openAside + 35 : "",

    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
});


export default styles;