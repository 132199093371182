import { Divider, IconButton, List, ListItem, ListItemText, Tooltip, Typography, Chip, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import Tree from '~/Components/Tree';
import { asEntity } from '~/Hoc';
import GroupForm from '../Partials/GroupForm';
import styles from './styles';
import PermissionsModal from '~/Screens/Shared/Permissions'

class EditGroup extends React.Component {
    view = "show";
    data = {};
    loading = false;
    id = this.props.match?.params?.id ? this.props.match.params.id : '';
    isPermissionModalOpen = false;

    componentDidMount() {
        if (!!this.id) {
            this.loading = true;
            this.props.entityStore.get(this.id)
        }
    }

    setView = (view) => {
        this.view = view;
    }

    handlePermissionModalClose = () => {
        console.log('done')
        this.isPermissionModalOpen = false;
    }

    handlePermissionModalOpen = () => {
        this.isPermissionModalOpen = true;
    }   

    updatePermissions = () =>  {
        this.loading = true;
        this.props.entityStore.get(this.id)
    }

    entityDidReceived(data) {
        if(!!data){
            this.loading = false;
            let { name, details, active, company } = data;
    
            this.data = {
                Name: name,
                Company: company.name,
                Details: details,
                Active: <Chip label={!!active ? "YES" : "NO"} />,
            }
        }
    }

    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.root}>
                {
                    this.view === "show" &&
                    <div className={classes.Show}>
                        <div className={classes.ShowHeader}>
                            <Typography variant="h6">
                                User Info.
                            </Typography>
                            <Tooltip className={classes.EditButton} title="Edit Group Info.">
                                <IconButton aria-label="Edit Group Info." onClick={() => this.setView("edit")}>
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                        </div>

                        <List className={classes.InfoPanel}>
                            {
                                !!this.data &&
                                Object.keys(this.data).map((key, index) =>
                                    <React.Fragment key={index}>
                                        <ListItem key={index} button>
                                            <ListItemText primary={
                                                <>
                                                   <span className={classes.ListItemText}> <strong> {key} </strong>  </span>
                                                    <span>  {this.data[key]} </span>
                                                </>
                                            } />
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                )
                            } 
                        </List>

                  

                        <div className={classes.ShowHeader}>
                            <Typography variant="h6">
                                Group Permissions
                            </Typography>
                            <Tooltip className={classes.EditButton} title="Edit Group Permissions">
                                <IconButton aria-label="Edit Group Permissions" onClick={this.handlePermissionModalOpen}>
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                        </div>
                        
                        <PermissionsModal 
                            open={this.isPermissionModalOpen} 
                            handleClose={this.handlePermissionModalClose}
                            updatePermissions={this.updatePermissions}
                            id={this.id}
                        />

                        <Tree uri={`Account/GetGroupPermissions/${this.id}`} 
                            title="" 
                            type="group"
                        />

                    </div>
                }

                {
                    this.view === "edit" && <div className={classes.Edit}>
                        <GroupForm {...this.props} setView={this.setView} />
                    </div>

                }

            </div>
        )

    }
}

decorate(EditGroup, {
    view: observable,
    isPermissionModalOpen: observable
})

export default withStyles(styles)(asEntity({ storeId: "GroupForm" })(observer(EditGroup)));
