export class PermissionsNormalizer {
    constructor(company) {
        let { title, permissions, type } = company;
        this.type = type;
        this.permissions = permissions;
        this.normalizedPermissions = {
            title,
            children: {}
        };
    }

    getUserAppNames() {
        let apps = [];
        this.permissions.forEach(permission => {
            let app = permission.split('.')[0];
            if (!apps.includes(app))
                apps.push(app);
        });
        return apps;
    }

    getGroupAppNames() {
        let apps = [];
        this.permissions.forEach(permission => {
            if (!apps.includes(permission.appName)) apps.push(permission.appName.toLowerCase());
        });
        return apps;
    }

    normalizeUserPermissions() {
        let apps = this.getUserAppNames(this.permissions);
        let children = this.normalizer(apps);
        this.normalizedPermissions.children = children;
    }

    normalizeGroupPermissions() {
        let apps = this.getGroupAppNames(this.permissions);
        let children = this.normalizer(apps);
        this.normalizedPermissions.children = children;
    }

    normalizer(apps) {
        let children = {};
        apps.forEach(app => {
            let temp = [];
            this.permissions.forEach(permission => {
                let name = this.type === "user" ? permission : permission.name;
                if (name.split('.')[0] === app) temp.push(name.split('.')[1])
            });
            children[app] = temp;
        });
        return children;
    }

    normalize() {
        switch (this.type) {
            case 'user':
                this.normalizeUserPermissions();
                break;
            case 'group':
                this.normalizeGroupPermissions();
                break;
        }
        return this.normalizedPermissions;
    }
}



export const normalizePatchData = (data) => {
    let normalizedData = [];

    Object.keys(data).forEach((key) => {
        normalizedData.push({
            op: "replace",
            path: `/${key}`,
            value: data[key],
        })
    })

    return normalizedData;
}
