import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    host: Yup.string().required(),
    connectionString: Yup.string().trim()
        .required("Connection string is a required field"),
    name: Yup.string().trim().required(),
    address: Yup.string().trim(),
    details: Yup.string().trim(),
    licnExpiration: Yup.date().required("Expiration date is required"),
    numProUser: Yup.number().required("Pro users number is required"),
    numLimUser: Yup.number().required("Limited users number is required"),
    locked: Yup.string().required(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        host: "",
        connectionString: "",
        name: "",
        phone: "",
        address: "",
        details: "",
        licnExpiration: "",
        numProUser: "",
        numLimUser: "",
        locked: "",
    },
};
export const formData = [
    { name: "host", type: "text", width: "48%", placeholder: "Host", },
    { name: "name", type: "text", width: "48%", placeholder: "Name", },
    { name: "connectionString", type: "text", placeholder: "Connection String", },
    { name: "phone", width: "48%", type: "phone", placeholder: "Phone", },
    { name: "address", width: "48%", type: "text", placeholder: "Address", },
    { name: "details", type: "textarea", placeholder: "Details", maxWords: 50 },
    { name: "licnExpiration", type: "date", focused: true, labelWidth: 140, placeholder: "Licence Expiration", },
    { name: "numProUser", type: "number", width: "40%", placeholder: "Pro Users Num", },
    { name: "numLimUser", width: "40%", type: "number", placeholder: "Limited Users Num", },
    { name: "locked", width: "20%", type: "switch", placeholder: "Locked", },
];