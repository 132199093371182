import { makeStyles } from '@material-ui/core'


const useStyles = makeStyles(theme => {
    return {
        label: {
            color: '#FFF'
        }
    }
})


export default useStyles;