import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import createStore from "./Redux";
import Routes from "./Routes";
import Notification from './Components/Notification'
import { I18nProvider } from "./Services/i18n";
import { AppContextProvider, NotificationContextProvider, ThemeContextProvider, UserContextProvider } from "./Services/Providers";
import './App.css';

var store = createStore();

function App() {
  return (
    <Provider store={store}>
      <NotificationContextProvider>
        <UserContextProvider>
          <AppContextProvider>
            <I18nProvider>
              <ThemeContextProvider>
                <Router>
                  <Routes />
                </Router>
                <Notification /> 
              </ThemeContextProvider>
            </I18nProvider>
          </AppContextProvider>
        </UserContextProvider>
      </NotificationContextProvider>
    </Provider>
  );
}

export default App;
