import React from 'react'
import Styles from './styles'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '../FormControl'
import { withFormik } from '~/Hoc'

class FormBuilder extends React.Component {

    handleSubmit = (e) => {
        e.preventDefault();
        let validate = this.props.validateForm();

        validate.then((err) => {
            if (this.props.isValid) {
                let { values } = this.props;

                console.log(this.props.values)
                console.log(this.getEditedFields())
                if (this.isEdit()) this.props.submitAction(values, this.getEditedFields())
                else this.props.submitAction(values)


            } else {
                console.log(this.props.values);
                console.log(this.props.errors);
                console.log('validation error');
            }
        });
    }

    isEdit = () => Boolean(!!this.props.editValues && Object.keys(this.props.editValues).length)

    getEditedFields = () => {
        let touchedFields = this.props.touched;
        let editedFields = [];

        Object.keys(touchedFields).forEach(key => {
            if (touchedFields[key]) {

                editedFields.push({
                    op: 'replace',
                    path: `/${key}`,
                    value: this.props.values[key],
                })

            }
        })

        return editedFields;
    }

    componentDidMount = () => {
        if (this.isEdit()) {
            this.props.setValues(this.props.editValues, true)
        }
    }


    render() {
        const { customStyle, formData, formName, children, classes } = this.props;

        return (
            <form noValidate onSubmit={this.handleSubmit} >
                <div className={classes.root}>
                    <div className={classes.formWrapper} style={!!customStyle ? { ...customStyle } : {}} >
                        {
                            formData.map((field, index) => (
                                <FormControl
                                    key={index}
                                    field={field}
                                    editState={this.isEdit()}
                                    value={this.props.values[field.name]}
                                    InputChange={this.props.handleChange}
                                    SetFieldValue={this.props.setFieldValue}
                                    error={!!this.props.errors[field.name]}
                                    errorText={this.props.errors[field.name]}
                                    formName={formName}
                                    editValue={!!this.props.editValues[field.name] ? this.props.editValues[field.name] : ''}
                                    blur={this.props.handleBlur}
                                />
                            ))
                        }
                    </div>

                    {children}
                </div>
            </form>
        )

    }
}

export default withStyles(Styles)(withFormik(FormBuilder))
