import { Button, withStyles } from '@material-ui/core';
import { decorate, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { FormBuilder, ActionButton } from '~/Components/From';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { NotificationContext } from '~/Services/Providers';
import { formData, formikProps, } from './data';
import useStyles from "./styles";
class ItemForm extends React.Component {
    static contextType = NotificationContext;

    actionType = this.props.type;
    values = {};

    componentWillMount() {
        const haveSlug = !formData.find(element => element.name === "Slug");
        if (this.props.type == "edit") {
            let permissions = []
            if (this.props.editValues.node.permissions) {
                for (let i = 0; i < toJS(this.props.editValues.node.permissions).length; i++) {
                    permissions.push({ name: toJS(this.props.editValues.node.permissions)[i], id: toJS(this.props.editValues.node.permissions)[i] })
                }
            }
            this.values = { ...this.props.editValues.node, permissions: permissions };

            if (haveSlug) {
                if (!this.values.Slug) formData.splice(2, 0, { name: "Slug", type: "text", width: "48%", placeholder: "Slug", });
                else formData.splice(2, 0, { name: "Slug", type: "text", width: "48%", placeholder: "Slug", editDisabled: true });
            }
        }
        else { haveSlug && formData.splice(2, 0, { name: "Slug", type: "text", width: "48%", placeholder: "Slug" }); }
    }
    arrayObjFix = (array, name) => {
        let element = [];
        for (let index = 0; index < array.length; index++) {
            element.push(array[index][name])
        }
        return element
    }

    handleSubmit = (values, editValues = {}) => {
        let NEW_NODE = {};
        if (this.actionType == "add") {
            NEW_NODE = {
                title: values.EnglishLabel.trim(),
                EnglishLabel: values.EnglishLabel.trim(),
                ArabicLabel: values.ArabicLabel.trim(),
                Slug: values.Slug.trim(),
                icon: values.icon,
                key: values.EnglishLabel.trim(),
                permissions: this.arrayObjFix(values.permissions, "name"),
            }
        } else {
            const node = this.props.node;
            const newValues = {
                ...values,
                permissions: this.arrayObjFix(values.permissions, "name"),
                title: values.EnglishLabel.trim(),
                EnglishLabel: values.EnglishLabel.trim(),
                ArabicLabel: values.ArabicLabel ? values.ArabicLabel : "",
                Slug: values.Slug.trim(),
            }
            NEW_NODE = {
                ...node,
                ...newValues,
            }
        }

        this.props.handelSubmit(this.props.editValues, NEW_NODE);
    };





    successActions = (message) => {
        this.loading = false;
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity: 'success',
            message
        }
        ))
    }

    entityDidCatch(errors) {
        this.loading = false;
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity: 'success',
            message: errors.title
        }
        ))
    }


    render() {
        const { classes } = this.props;

        if (this.getLoading) {
            return <Loading />
        }
        return (
            <>
                <FormBuilder {...formikProps}
                    formData={formData}
                    formName="ItemForm"
                    editValues={this.values}
                    submitAction={this.handleSubmit}
                    customStyle={{ flexBasis: "100%" }}
                >
                    <div style={{ flexBasis: "100%", display: 'flex', justifyContent: 'flex-end' }}>

                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.SubmitButton}
                            onClick={(e) => this.props.handleClose()}
                        >
                            Cancel
                        </Button>
                        <ActionButton customClass={classes.SubmitButton} title={this.actionType === 'edit' ? 'Edit' : 'Add'}
                            icon={this.actionType === 'edit' ? 'arrow_forward' : 'add'}
                            loading={this.loading}
                        />

                    </div>
                </FormBuilder>
            </>

        )
    }
}


decorate(ItemForm, {
    loading: observable,
})

export default withStyles(useStyles)(observer(ItemForm));



