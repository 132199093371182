
const Styles = theme => ({
    root: {
        minWidth: 200,
        textAlign: theme.direction === "ltr" ? 'left' : "right",
    },
    dropdownControl: {
        width: "100%",
        margin: `${theme.spacing(2)}px`
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

export default Styles;