import { Button, withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { NotificationContext } from '~/Services/Providers';
import { formData, editFormData, editFormikProps, formikProps } from './data';
import useStyles from "./styles";
import { ActionButton, FormBuilder } from '~/Components/From';


class UserForm extends React.Component {
    static contextType = NotificationContext;
    loading = false; // true , false
    getLoading = false;
    actionType = "Add";
    values = {};
    email = this.props.match?.params?.email ? this.props.match.params.email : '';
    data = formData;
    formikProps = formikProps;

    componentDidMount() {
        if (!!this.email) {
            this.data = editFormData;
            this.formikProps = editFormikProps;
            this.getLoading = true;
            this.props.entityStore.get(this.email);
        }
    }

    handleSubmit = (values, editValues = {}) => {
        this.loading = true;
        if (this.actionType !== "Edit") this.props.entityStore.post(values);
        else this.props.entityStore.patch({ email: values.email, editValues })
    };

    entityDidReceived(data) {
        if (!!data) {
            this.getLoading = false;
            let normalizedData = {};

            //removing null
            Object.keys(data).map(key => {
                if (data[key] === null) normalizedData[key] = '';
                else normalizedData[key] = data[key];
            })

            normalizedData.permissionLevel = { id: normalizedData.permissionLevel }
            console.log(normalizedData);
            this.values = normalizedData;
        }
    }

    entityDidPosted(data) {
        this.successActions('User Added successfully');
    }

    entityDidUpdated(data) {
        this.successActions('User Updated successfully')
    }

    successActions = (message) => {
        this.loading = false;
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity: 'success',
            message
        }
        ))
        this.props.history.push('/administration/users');
    }

    entityDidCatch(errors) {
        this.loading = false;
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity: 'error',
            message: errors.title
        }
        ))
        console.log("my error", errors)
    }

    render() {
        const { classes } = this.props;

        if (this.getLoading) {
            return <Loading />
        }

        return (
            <div className={classes.FormWrapper}>
                <FormBuilder  {...this.formikProps}
                    formData={this.data}
                    formName="AuthRegister"
                    editValues={this.values}
                    submitAction={this.handleSubmit}
                    customStyle={{ flexBasis: "100%" }}
                >
                    <div style={{ flexBasis: "100%", display: 'flex', justifyContent: 'flex-end' }}>

                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.SubmitButton}
                            onClick={(e) => !!this.email ? this.props.setView("show") : this.props.history.goBack()}
                        >
                            Cancel
                        </Button>

                        <ActionButton customClass={classes.SubmitButton} title={this.email !== '' ? 'Duplicate' : 'Add User'}
                            icon={this.email !== '' ? 'add' : 'arrow_forward'}
                            loading={this.loading}
                        />
                        {
                            this.email !== '' &&
                            <ActionButton customClass={classes.SubmitButton} title="Update User" icon="arrow_forward" loading={this.loading}
                                onClick={() => this.actionType = "Edit"}
                            />
                        }
                    </div>
                </FormBuilder>
            </div>

        )
    }
}


decorate(UserForm, {
    loading: observable,
})

export default withStyles(useStyles)(asEntity({ storeId: "UserForm" })(observer(UserForm)));
