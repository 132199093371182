const styles = theme => ({
    root: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalWrapper: {
        background: theme.palette.type === "light" ? theme.palette.common.white : "#212121",
        padding: theme.spacing(2),
        minWidth: '600px',
        maxWidth: '750px',
        paddingRight: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            minWidth: '400px',
            maxWidth: '450px',
        }
    },
    SubmitButton: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(4),
    },
})

export default styles;