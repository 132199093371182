const Styles = (theme) => ({
    root : {
        paddingBottom : theme.spacing(3),
        display : "flex",
        flexWrap : "wrap"
    },
    formWrapper : {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        padding: "1em",
    }
});

export default Styles;
