import React, { Component } from 'react';
import { withStyles, Modal, Button, Fade, Backdrop } from '@material-ui/core';
import { observer } from 'mobx-react';
import { asEntity } from '~/Hoc';
import { ActionButton, FormBuilder } from '~/Components/From'
import styles from './styles.js';
import { formData, formikProps } from './data';
import { observable, decorate } from 'mobx'
import { NotificationContext } from '~/Services/Providers';

export class Permissions extends Component {
    static contextType = NotificationContext;
    loading = false;
    values = {};

    componentDidMount() {
        this.loading = true;
        let { id } = this.props;
        this.props.entityStore.get({ id, key: "getGroupPermissions" })
    }

    handleSubmit = (values, editValues = {}) => {
        this.loading = true;
        let permissionIds = [];
        values.permissions.forEach(value => permissionIds.push(value.id));
        let editData = {
            group_Id: this.props.id,
            permissions: permissionIds
        }
        this.props.entityStore.post(editData)
    }

    entityDidReceived(data) {
        if (!!data) {
            this.values = {
                permissions: data
            }
            this.loading = false;
        }
    }

    entityDidPosted(data) {
        this.loading = false;
        this.props.handleClose();
        this.props.updatePermissions();
        this.notify("Success", "Permissions updated successfully");
    }


    notify = (severity, message) => {
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity,
            message
        }))
    }

    render() {
        const { open, handleClose, classes } = this.props;
        return (
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className={classes.root}
            >
                <Fade in={open} >
                    <div className={classes.modalWrapper}>
                        <FormBuilder {...formikProps}
                            formData={formData}
                            formName="Permissions"
                            editValues={this.values}
                            submitAction={this.handleSubmit}
                            customStyle={{ flexBasis: "100%" }}
                        >
                            <Button variant="outlined"
                                color="secondary"
                                className={classes.SubmitButton}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>

                            <ActionButton customClass={classes.SubmitButton} title="Save"
                                icon='arrow_forward'
                                loading={this.loading}
                            />
                        </FormBuilder>
                    </div>
                </Fade>
            </Modal>
        )
    }
}

decorate(Permissions, {
    loading: observable,
})

export default withStyles(styles)(asEntity({ storeId: "PermissionForm" })(observer(Permissions)));
