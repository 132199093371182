import React from 'react'
import GroupForm from '../Partials/GroupForm'
import useStyles from './styles'

function AddGroup(props) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <GroupForm {...props}  />
        </div>
    )
}

export default AddGroup;
