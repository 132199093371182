

import { List, ListSubheader } from '@material-ui/core';
import React , {useState} from 'react';
import PasswordForm from './Partials/PasswordForm';
import ProfileForm from "./Partials/ProfileForm";
import { profileFormProps , passwordFormProps } from './data'
import { useUserContext } from '../../Services/Providers'

import useStyles from './styles';

function Profile() {
  const classes = useStyles()
  const { userContextObj } = useUserContext();
  const [OpenTab, setOpenTab] = useState(null)


  const handleTabClick = (Tab) => {
    Tab === OpenTab 
      ? setOpenTab(null)
      : setOpenTab(Tab);
  }

  return (
    <List component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Personal Info.
        </ListSubheader>
      }
      className={classes.root}
    >

      <ProfileForm  OpenTab={OpenTab} 
        handleTabClick={handleTabClick}
        userContextObj={userContextObj} 
        {...profileFormProps}
      />

      <PasswordForm OpenTab={OpenTab} 
        handleTabClick={handleTabClick} 
        {...passwordFormProps}
      />

    </List>
  )
}

export default Profile
