import React, { useEffect } from 'react'
import { Search } from '@material-ui/icons'
import { InputBase, makeStyles } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { privateRoutes } from '~/Routes/RouteList'
import { useHistory } from "react-router-dom";
import { UpperCaseFirst } from '../../../../../Services/Helpers'

const useStyles = makeStyles(theme => ({
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: theme.direction === "ltr" ? `calc(1em + ${theme.spacing(4)}px)` : '',
        paddingRight: theme.direction === "rtl" ? `calc(1em + ${theme.spacing(4)}px)` : '',
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    }
}))

const autoOptions = [
    { title: 'option1', value: '1' },
    { title: 'option2', value: '2' }
]

function SearchBar() {
    const classes = useStyles();
    const history = useHistory()
    const normalizedData = [];
    useEffect(() => {
        privateRoutes.map((route) => {
            if (!route.path.includes('add') && !route.path.includes('edit') && !route.path.includes('id') && !route.path.includes('/*') && !route.path.includes('email')) {
                let realPath = route.path.substring(route.path.lastIndexOf('/') + 1);
                realPath = realPath.replace(/-/g, " ");
                normalizedData.push({ title: realPath, path: route.path });

            }

        })
    });
    const forwardPage = path => {
        history.push(path);
    }

    return (
        <>
            <div className={classes.searchIcon}>
                <Search />
            </div>
            <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={normalizedData}
                getOptionLabel={(option) => option.title}
                renderOption={(option) => (
                    <React.Fragment>
                        <div style={{ width: "100%" }} onClick={() => forwardPage(option.path)}>
                            {UpperCaseFirst(option.title)}
                        </div>
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <InputBase
                        {...params}
                        ref={params.InputProps.ref}
                        inputProps={params.inputProps}
                        autoFocus
                        placeholder="Search…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                    />
                )}
            />

        </>
    )
}

export default SearchBar;
