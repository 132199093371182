import { Modal, Fade, Backdrop } from "@material-ui/core";

import React from "react";
import { classNames } from "classnames";
import useStyles from "./styles";


function EditModal({ open, handleClose, customClasses, title, children }) {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      className={classes.root}
    >
      <Fade in={open}>
        <div className={classes.modalWrapper} >
          {!!title && <p className={classes.header}>{title}</p>}
          {children}
        </div>

      </Fade>
    </Modal>
  );
}

export default EditModal;
