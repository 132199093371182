import { FormControlLabel, Switch } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import useStyles from './styles'

function SwitchInput({ name, placeholder, editValue, InputChange, error, errorText, blur }) {
    const classes = useStyles;
    const [Checked, setChecked] = useState(false)
    const theme = useTheme();

    const handleChange = () => {
        InputChange(name, !Checked);
        setChecked(!Checked);
    }

    useEffect(() => {
        if (typeof editValue !== 'undefined' && !!editValue) {
            InputChange(name, editValue);
            setChecked(editValue);
        } else {
            InputChange(name, false);
        }
    }, [])


    return (
        <>
            <FormControlLabel
                control={<Switch
                    checked={Checked}
                    onChange={handleChange}
                    onBlur={blur}
                    name={name}
                    color="secondary"
                    inputProps={{ 'aria-label': `${name} checkbox` }}
                />}
                style={{ color: error ? theme.palette.error.main : theme.palette.common.black }}
                label={placeholder}
            />
            <small style={{ color: theme.palette.error.main }} > {!!errorText && errorText} </small>
        </>
    )
}

export default SwitchInput
