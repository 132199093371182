import * as Yup from "yup";

export const addValidationSchema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string()
    .trim()
    .required("email is a required field")
    .email("please enter a valid email"),
  permissionLevel: Yup.string().required(),
  phoneNumber: Yup.string().notRequired(),
  // saP_User: Yup.string().required(),
  isSapEnabled: Yup.bool().required(),
  active: Yup.bool().required(),
  password: Yup.string()
    .trim()
    .required()
    .matches(/((?=.*[0-9]).{1,})+/, 'Should have 1 digit minimum')
    .matches(/((?=.*[a-z]).{1,})+/, 'Should have 1 character minimum')
    .matches(/((?=.*[a-z])(?=.*[0-9]).{6,8})+/, 'Must be  6 to 8 characters'),
  passwordConfirm: Yup.string()
    .trim()
    .required("password confirm is a required field")
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  saP_Password: Yup.string()
    .trim()
    .when('isSapEnabled', {
      is: true,
      then: Yup.string().trim().matches(/((?=.*[0-9]).{1,})+/, 'Should have 1 digit minimum')
        .matches(/((?=.*[a-z]).{1,})+/, 'Should have 1 character minimum')
        .matches(/((?=.*[a-z])(?=.*[0-9]).{6,8})+/, 'Must be  6 to 8 characters'),
      otherwise: Yup.string().trim().matches(/((?=.*[0-9]).{1,})+/, 'Should have 1 digit minimum')
        .matches(/((?=.*[a-z]).{1,})+/, 'Should have 1 character minimum')
        .matches(/((?=.*[a-z])(?=.*[0-9]).{6,8})+/, 'Must be  6 to 8 characters'),
    }),
  saP_Password_Confirm: Yup.string()
    .trim()
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export const editValidationSchema = Yup.object().shape({
  name: Yup.string().required(),
  phoneNumber: Yup.string().notRequired(),
  email: Yup.string()
    .trim()
    .required("email is a required field")
    .email("please enter a valid email"),
  permissionLevel: Yup.string().required(),
  // saP_User: Yup.string().required(),
  isSapEnabled: Yup.bool().required(),
  active: Yup.bool().required(),
});


export const formikProps = {
  validationSchema: addValidationSchema,
  formik: {
    onChangeValidate: false,
    onBlurValidate: false,
  },
  initialVals: {
    name: "",
    email: "",
    phoneNumber: "",
    password: "",
    // saP_Password: "",
    permissionLevel: "",
    saP_User: "",
    isSapEnabled: false,
    active: false
  },
};

export const editFormikProps = {
  validationSchema: editValidationSchema,
  formik: {
    onChangeValidate: false,
    onBlurValidate: false,
  },
  initialVals: {
    name: "",
    email: "",
    phoneNumber: "",
    permissionLevel: "",
    // saP_User: "",
    isSapEnabled: false,
    active: false
  },
};

const PermissionsOptions = [
  { id: 1, name: 'Professional' },
  { id: 2, name: 'Limited' }
]

export const formData = [
  { name: "name", type: "text" },
  { name: "email", type: "email", editDisabled: true },
  // { name: "saP_User", type: "text", },
  { name: "phoneNumber", type: "phone", },
  { name: "permissionLevel", type: "dropdown", options: PermissionsOptions },
  { name: "password", type: "password", width: "48%" },
  { name: "passwordConfirm", type: "password", width: "48%" },
  { name: "saP_Password", type: "password", width: "48%" },
  { name: "saP_Password_Confirm", type: "password", width: "48%" },
  { name: "isSapEnabled", width: "20%", type: "switch" },
  { name: "active", width: "20%", type: "switch" },

];

export const editFormData = [
  { name: "name", type: "text" },
  { name: "email", type: "email", editDisabled: true },
  // { name: "saP_User", type: "text" },
  { name: "phoneNumber", type: "phone" },
  { name: "permissionLevel", type: "dropdown", options: PermissionsOptions },
  { name: "isSapEnabled", width: "20%", type: "switch" },
  { name: "active", width: "20%", type: "switch" },
];

