import React from 'react';
import { PropTypes } from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles'

function Loading({ classes, size , thickness }) {
  return (
    <CircularProgress 
      className={classes.circularProgress} 
      size={!!size ? size : 90} 
      thickness={!!thickness ? thickness : 1} 
      color="secondary" 
    />);
}

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default (withStyles(styles)(Loading));
