import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        "& > .react-tel-input": {
            margin: theme.spacing(2),
        },
        "&  > .react-tel-input input": {
            width: "100%",
            "&:focus": {
                borderColor: theme.palette.primary.main,
                boxShadow: `0 0 0 1px ${theme.palette.primary.main}`
            }
        },
        "& .react-tel-input .form-control+div:before": {
            right: theme.direction === "rtl" ? "25px" : "unset",
            left: theme.direction === "ltr" ? "25px" : "unset",
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.type == "dark" ? "#fff" : "unset",

        },
        "& .react-tel-input .form-control:hover": {
            borderColor: theme.palette.type == "dark" ? "#FFF" : "#000"
        },
        "& .react-tel-input .selected-flag .arrow": {
            right: theme.direction === "rtl" ? "29px" : "unset",
            left: theme.direction === "ltr" ? "29px" : "unset",
        },
        "& .react-tel-input .form-control": {
            padding: theme.direction === "ltr" ? " 18.5px 14px 18.5px 58px" : " 18.5px 58px 18.5px 14px",
        },
        "& .react-tel-input .form-control": {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.type == "dark" ? "#fff" : "",
        }
    }
}));


export default useStyles;