import React, { Component } from "react";
import { Formik } from "formik";

const withFormik = (WrappedComponent) => {
  class WithFormik extends Component {
    render() {
      return (
        <Formik
          initialValues={this.props.initialVals}
          validationSchema={this.props.validationSchema}
          validateOnChange={this.props.formik.onChangeValidate}
          validateOnBlur={this.props.formik.onBlurValidate}
          
          onSubmit={( values, { setSubmitting }) => {
            setSubmitting(false);
          }}
        >
          {(formikProps) => (
            <WrappedComponent {...this.props} {...formikProps} />
          )}
        </Formik>
      );
    }
  }
  return WithFormik;
};

export default withFormik;
