import { Drawer, Hidden, List } from '@material-ui/core';
import classNames from 'classnames';
import React , {useState} from 'react';
import { useHistory } from 'react-router-dom';
import Navigator from '~/Routes/RouteNavigator';
import useStyles from './styles';
import AsideItem from './Partials/AsideItem';
import AsideTile from './Partials/AsideTile';

function Aside({ handleDrawerToggle, open, }) {
    const classes = useStyles();
    const history = useHistory();

    const { data: navData, active: activeTile } = Navigator;
    const [activeNav, setActiveNav] = useState({activeTile : '' , activeItem : '' })
    
    const MainMenu = () => (<div> {
        Object.keys(navData).map((key, index) =>
            <AsideTile
                key={index}
                name={key}
                icon={navData[key].icon}
                active={activeNav.activeTile === key}
                handleClick={() => {
                    handleDrawerToggle(true);
                    setActiveNav({ activeTile :key ,  activeItem : navData[key].activeItem })
                    history.push(navData[key].activeItem)
                }}
            />
        )

    } </div>);
    
    const SubMenu = () => (<List style={{ marginLeft :15 , width : '100%'}}> {
            !!activeNav.activeTile && navData[activeNav.activeTile].sections.map((element, index) =>
                <AsideItem
                    key={index}
                    name={element.label}
                    icon={element.icon}
                    active={activeNav.activeItem === element.path}
                    handleClick={() => {
                        setActiveNav(currState => ({
                            ...currState, 
                            activeItem : element.path
                        }))
                        history.push(element.path)
                    }}
                />

            )
        }
    </List>);
    
    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <div style={{ display: 'flex' }}>
                <MainMenu />
                <SubMenu />
            </div>
        </div >
    );

    return (
        <nav>
            <Hidden mdUp>
                <Drawer
                    variant="temporary"
                    anchor="left"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    onClose={handleDrawerToggle}
                >
                    {drawer}

                </Drawer>
            </Hidden>
            <Hidden smDown implementation="js">
                <Drawer
                    style={{ height: "100%" }}
        
                    variant="permanent"
                    classes={{
                        paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}
                >

                    {drawer}
                </Drawer>
            </Hidden>
        </nav>
    )
}

export default Aside;
