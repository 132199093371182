import { AppBar, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Brightness6, Fullscreen, FullscreenExit, Brightness7 } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import React, { useState } from 'react';
import { FullscreenHandler, toAbsoluteUrl } from '~/Services/Helpers';
import MenuProfile from '../MenuProfile';
import LangSelector from './Partials/LangSelector';
import SearchBar from './Partials/Search';
import useStyles from './styles';
import { useAppContext } from '~/Services/Providers';
import { setStorage } from '~/Services/Helpers';

const fullscreenElem = document.documentElement;
const ScreenHandler = new FullscreenHandler(fullscreenElem);

function Header({ handleDrawerToggle, drawerOpen }) {
    const { appContextObj, setAppContext } = useAppContext();

    const classes = useStyles();
    const [FullScreen, setFullScreen] = useState(false);

    const smScreen = useMediaQuery(theme => theme.breakpoints.up('sm'));



    const setContext = (name) => {
        setAppContext((currContext) => ({ ...currContext, theme: name }));
        setStorage('theme', name);
    };
    const toggleFullScreen = (e) => {
        FullScreen
            ? ScreenHandler.closeFullScreen()
            : ScreenHandler.openFullScreen();
        setFullScreen(!FullScreen);
    }
    return (
        <AppBar
            position="fixed"
            className={classes.appBar}
        >
            <Toolbar className={classes.toolBar}>
                <div>
                    <div className={classes.menuItemIcon}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => handleDrawerToggle(!drawerOpen)}
                            className={classNames(classes.menuButton)}
                        >
                            <MenuIcon />
                        </IconButton>

                    </div>
                    <div className={classes.menuItemIcon}>
                        <Typography variant="h6" color="inherit" noWrap>
                            <img className={classes.Logo} src={toAbsoluteUrl("/logo-light.png")} />
                        </Typography>
                    </div>
                </div>

                <div>
                    {
                        smScreen &&
                        <>
                            <div className={classNames(classes.search, classes.menuItem)}>
                                <SearchBar />
                            </div>


                            <div className={classes.menuItem}>
                                <LangSelector />
                            </div>


                            <div className={classes.menuItemIcon}>
                                <Tooltip title={`${FullScreen ? 'Close' : ''} Fullscreen`}>
                                    <IconButton onClick={toggleFullScreen} aria-label="delete">
                                        {
                                            FullScreen
                                                ? <FullscreenExit className={classes.menuIcon} />
                                                : <Fullscreen className={classes.menuIcon} />
                                        }
                                    </IconButton>
                                </Tooltip>
                            </div>

                            <div className={classes.menuItemIcon}>
                                <Tooltip title="Turn on Dark/Light">
                                    {(appContextObj.theme === "light") ? (
                                        <IconButton onClick={() => setContext("dark")}>
                                            <Brightness6 className={classes.menuIcon} />
                                        </IconButton>
                                    ) :
                                        (
                                            <IconButton onClick={() => setContext("light")}
                                            >
                                                <Brightness7 />
                                            </IconButton>
                                        )}
                                </Tooltip>
                            </div>
                        </>
                    }

                    <div className={classes.menuItem} >
                        <MenuProfile />
                    </div>

                </div>
            </Toolbar>
        </AppBar>
    )
}


export default Header;