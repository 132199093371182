import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            height: "100vh",
            width: "100vw",
            display: "flex",
            direction: theme.direction,
            backgroundColor: theme.palette.type === "light" ? "#fff" : "#212121",
        },

        AuthSide: {
            flex: .4,
            order: 1,
            color: theme.palette.light.main,
            backgroundColor: theme.palette.primary.light,
            [theme.breakpoints.down('sm')]: {
                display: "none"
            },
            "& > div": {
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",

            },
        },

        FormWrapper: {
            flex: .6,
            order: 2,
            textAlign: 'center',
            padding: `${theme.spacing(1)}px ${theme.spacing(10)}px`,
            [theme.breakpoints.down('sm')]: {
                flex: 1,
                padding: `0 ${theme.spacing(5)}px`,
                paddingLeft: theme.spacing(2),
            },
        },

        WelcomeMsg: {
            padding: theme.spacing(6),
            "& h2": {
                fontSize: theme.typography.h3.fontSize,
                fontWeight: theme.typography.fontWeightRegular
            },
            "& span": {
                fontSize: theme.typography.h6.fontSize,
                fontWeight: "700",
                fontWeight: theme.typography.fontWeightRegular
            }
        },
        LangSelect: {
            width: "150px",
            maxHeight: "50px",
            alignSelf: "flex-end",
            margin: theme.spacing(5),
            color: theme.palette.light.main,
            "& > div": {
                paddingTop: "15px"
            }
        },
        Logo: {
            width: "205px",
            marginLeft: theme.spacing(4)
        },
        LogoMobile: {
            width: "205px",
            margin: theme.spacing(5),
            marginBottom: 0,
            filter: "brightness(0)"
        },
        divider: {
            textAlign: 'center',
            borderTop: `1px solid ${theme.palette.divider}`,
            fontSize: 14,
            color: theme.palette.text.secondary,
            margin: `${theme.spacing(6)}px 0 ${theme.spacing(3)}px`,
            '& span': {
                background: theme.palette.background.paper,
                top: -10,
                position: 'relative',
                padding: '0 10px',
            }
        },
        SocialAction: {
            padding: `${theme.spacing(10)}px 0`,
            textAlign: "center",
            [theme.breakpoints.down('sm')]: {
                padding: `${theme.spacing(5)}px 0`,
            },
            paddingTop: 0,
            "& a": {
                margin: theme.spacing(2)
            }
        },
        HeadTitle: {
            margin: `${theme.spacing(2)}px 0`,
            display: "flex",
            justifyContent: "space-between"
        }
    }
});


export default useStyles;