import React from 'react'
import { FormControl, InputLabel, Select, FormHelperText, MenuItem, withStyles } from '@material-ui/core'
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { onlyOneOfProps } from '~/Services/Helpers';
import Styles from './styles'

class Dropdown extends React.Component {
    value = "";
    options = [];
    loading = false;
    defaultValue = !!this.props.editValue?.id ? this.props.editValue.id : this.value;

    componentDidMount = () => {
        if (!!this.props.options) {
            this.props.InputChange(this.props.name, this.defaultValue)
            this.options = this.props.options
            this.value = this.defaultValue;
        }
        else {
            this.loading = true;
            this.props.entityStore.get({ uri: this.props.optionsURI, body: {} });
        }
    }

    handleChange = (event) => {
        this.value = event.target.value;
        this.props.InputChange(this.props.name, event.target.value);
    };

    entityDidReceived(data) {
        if(!!data){
            this.options = data;
            this.loading = false;
            this.props.InputChange(this.props.name, this.defaultValue)
            this.value = this.defaultValue;
        }
    }


    render() {
        let { error, errorText, name, width, placeholder, blur , classes  } = this.props;

        if (this.loading) {
            return <Loading />
        }
        return (
            <div className={classes.root} style={{ flexBasis: !!width ? width : "100%" }}>
                <FormControl variant="outlined" className={classes.dropdownControl}
                    error={error}
                >
                    <InputLabel id="dropdown-outlined-label"> {placeholder} </InputLabel>
                    <Select
                        labelId="dropdown-outlined-label"
                        id="dropdown-outlined"
                        value={this.value}
                        name={name}
                        onChange={this.handleChange}
                        label={placeholder}
                        onBlur={blur}
                    >

                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {
                            !!this.options && this.options.map((Item, index) => (<MenuItem key={index} value={Item.id}>{Item.name}</MenuItem>))
                        }

                    </Select>
                    {
                        error && <FormHelperText>{errorText}</FormHelperText>
                    }
                </FormControl>
            </div>
        )
    }
}


const onlyOneOfPropsData = {
    options: 'object',
    optionsURI: 'string'
}

Dropdown.propTypes = {
    options: onlyOneOfProps(onlyOneOfPropsData),
    optionsURI: onlyOneOfProps(onlyOneOfPropsData),
}

decorate(Dropdown, {
    options: observable
})


export default withStyles(Styles)(asEntity({ storeId: "GenericDropdown" })(observer(Dropdown)));
