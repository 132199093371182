import { Chip, Divider, IconButton, List, ListItem, ListItemText, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import MUIDataTable from "mui-datatables";
import React from 'react';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { columns as groupColumns } from '../../Group/Browse/data';
import { columns as userColumns } from '../../Users/Browse/data';
import CompanyForm from '../Partials/CompanyForm';
import styles from './styles';


class ViewCompany extends React.Component {
    view = "show";
    id = this.props.match?.params?.id ? this.props.match.params.id : '';
    data = {};
    loading = false;
    groups = [];
    users = [];
    componentDidMount() {
        if (!!this.id) {
            this.loading = true;
            this.props.entityStore.get(this.id)
        }
    }

    setView = (view) => {
        console.log(view)
        this.view = view;
        console.log(this.view)
    }

    entityDidReceived(data) {
        if (!!data) {
            this.loading = false;
            let { name, host, connectionString, phone, address, details, licnExpiration, numProUser, numLimUser, hasSAPIntegration, saP_User } = data.company;

            this.data = {
                Name: name,
                Host: host,
                Phone: phone,
                Address: address,
                Details: details,
                "Connection String": connectionString,
                "Licence Expires in": moment(licnExpiration).format("YYYY-MM-DD"),
                "Number of Pro Users": <Chip label={!!numProUser ? numProUser : 0} />,
                "Number of Limited Users": <Chip label={!!numLimUser ? numLimUser : 0} />,
                "Has SAP integration ": <Chip label={!!hasSAPIntegration ? "YES" : "NO"} />,
                "SAP user": saP_User,
            }
            this.groups = data.company.groups;
            this.users = data.users;

        }
    }


    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.root}>
                {
                    this.view === "show" &&
                    <div className={classes.Show}>
                        <div className={classes.ShowHeader}>
                            <Typography variant="h6">
                                Company Info.
                            </Typography>
                            <Tooltip className={classes.EditButton} title="Edit">
                                <IconButton aria-label="Edit" onClick={() => this.setView("edit")}>
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                        </div>


                        <List className={classes.InfoPanel} >
                            {
                                !!this.data &&
                                Object.keys(this.data).map((key, index) =>
                                    <>
                                        <ListItem key={index} button>
                                            <ListItemText primary={
                                                <>
                                                    <span className={classes.ListItemText}> <strong> {key} </strong>  </span>
                                                    <span>  {this.data[key]} </span>
                                                </>
                                            } />
                                        </ListItem>
                                        <Divider />
                                    </>
                                )
                            }
                        </List>
                        <br />
                        <MUIDataTable
                            title="Company Groups"
                            data={this.groups}
                            columns={groupColumns}
                            options={{
                                onRowClick: (group) => this.props.history.push(`/administration/group/${group[0]}`),
                                setRowProps: () => ({ style: { cursor: 'pointer' } }),
                                print: false,
                                download: false,
                                selectableRows: 'none',
                            }}
                        />
                        <br />
                        <MUIDataTable
                            title="Company Users"
                            data={this.users}
                            columns={userColumns}
                            options={{
                                onRowClick: (user) => this.props.history.push(`/administration/users/view/${user[1]}`),
                                setRowProps: () => ({ style: { cursor: 'pointer' } }),
                                print: false,
                                download: false,
                                selectableRows: 'none',
                            }}
                        />

                    </div>
                }
                {
                    this.view === "edit" && <div className={classes.Edit}>
                        <CompanyForm {...this.props} setView={this.setView} />
                    </div>
                }
            </div>
        )
    }
}

decorate(ViewCompany, {
    view: observable,
})




export default withStyles(styles)(asEntity({ storeId: "CompanyForm" })(observer(ViewCompany)));
