import React from 'react'
import { Chip } from '@material-ui/core'


export const columns = [
    { name: "id", label: "Id", options: { filter: false, sort: true, display: false } },
    { name: "host", label: "Host", options: { filter: false, sort: true, } },
    { name: "name", label: "Name", options: { filter: true, sort: true, } },
    { name: "phone", label: "Phone", options: { filter: false, sort: true, } },
    { name: "address", label: "Address", options: { filter: true, sort: true, } },
    { name: "connectionString", label: "Connection String", options: { filter: true, sort: true, display: false } },
    { name: "details", label: "Details", options: { filter: true, } },
    { name: "locked", label: "Locked", options: { filter: true, customBodyRender : (value , tableMeta, updateValue) => <Chip label={!!value ? "YES" : "NO"} /> } },
    { name: "numProUser", label: "Pro Users", options: { filter: true, } },
    { name: "numLimUser", label: "Limited Users", options: { filter: true, } },
]; 