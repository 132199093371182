import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
    .trim()
    .required()
    .matches(/((?=.*[0-9]).{1,})+/, 'wrong password')
    .matches(/((?=.*[a-z]).{1,})+/, 'wrong password')
    .matches(/((?=.*[a-z])(?=.*[0-9]).{6,8})+/, 'wrong password'),
    password: Yup.string()
    .trim()
    .required()
    .matches(/((?=.*[0-9]).{1,})+/, 'Should have 1 digit minimum')
    .matches(/((?=.*[a-z]).{1,})+/, 'Should have 1 character minimum')
    .matches(/((?=.*[a-z])(?=.*[0-9]).{6,8})+/, 'Must be  6 to 8 characters'),
  passwordConfirm: Yup.string()
    .trim()
    .required("password confirm is a required field")
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export const formData = [
    {
        name: "oldPassword",
        type: "password",
        size : "small",
        width : "60%",
    
    },
    {
        name: "password",
        type: "password",
        size : "small",
        width : "60%",
    },
    {
        name: "passwordConfirm",
        type: "password",
        size : "small",
        width : "60%",
    },

]

