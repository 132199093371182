import React from "react";
import Playground from "../Screens/Playground";
import Login from "../Screens/Auth/Login";
import Profile from "../Screens/Profile";

import BrowseUsers from "../Screens/Users/Browse";
import AddUser from "../Screens/Users/Add";
import ViewUser from "../Screens/Users/Edit-View";

import BrowseCompanies from "../Screens/Company/Browse";
import AddCompany from "../Screens/Company/Add";
import ViewCompany from "../Screens/Company/Edit-View";

import BrowseGroups from "../Screens/Group/Browse";
import AddGroup from "../Screens/Group/Add";
import ViewGroup from "../Screens/Group/Edit-View";

import MenuBuilder from "../Components/MenuBuilder"


const EmptyPage = () => (
  <div style={{ position: "absolute", top: "50px", left: "44%" }}>
    <h4> Page not build yet </h4>
  </div>
);

export const privateRoutes = [

  { path: "/" , component : Profile , exact : true }, 
  { path: "/profile" , component : Profile , exact : true }, 
  
  { path: "/administration/users" , component : BrowseUsers , exact : true }, 
  { path: "/administration/users/add" , component : AddUser , exact : true }, 
  { path: "/administration/users/view/:email" , component : ViewUser , exact : true }, 
  
  { path: "/administration/companies" , component : BrowseCompanies , exact : true }, 
  { path: "/administration/company/add" , component : AddCompany , exact : true }, 
  { path: "/administration/company/:id" , component : ViewCompany , exact : true }, 
  
  { path: "/administration/groups" , component : BrowseGroups , exact : true }, 
  { path: "/administration/group/add" , component : AddGroup , exact : true }, 
  { path: "/administration/group/:id" , component : ViewGroup , exact : true }, 

  { path: "/administration/menu" , component : MenuBuilder , exact : true }, 


  { path: "/*", component: EmptyPage, exact: true },
  
];

export const authRoutes = [
  { path: "/login", component: Login },
]

export const publicRoutes = [
  { path: "/pg", component: Playground },
];
