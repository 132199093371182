

import React, { Component } from 'react';
import ProfileListItem from '../ProfileListItem';
import { FormControl } from '~/Components/From';
import { Button } from '@material-ui/core';
import { withFormik , asEntity } from '~/Hoc'
import { observer } from 'mobx-react'
import { formData } from './data';
import { Form } from 'formik'
import { NotificationContext } from '~/Services/Providers';

export class PasswordForm extends Component {
    static contextType = NotificationContext;
    loading = false;
    handleSubmit = (e) => {
        e.preventDefault();
        let validate = this.props.validateForm();
   
        validate.then((err) => {
            if (this.props.isValid) {
                this.props.entityStore.put(this.props.values);
                // this.loading = true;
            }else {
                console.log(this.props.errors)
            }
        });
    }

    entityDidUpdated(){
        this.loading = false;
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity: 'success',
            message : 'Password updated successfully'
        }
        ))
    }

    render() {
        const {OpenTab , handleTabClick} = this.props;
        
        return (
            <Form id="password-form" noValidate onSubmit={this.handleSubmit}>
                <ProfileListItem handleTabClick={handleTabClick} open={"Change password" === OpenTab} 
                    title="Change password" 
                    secondaryTitle="It's a good idea to use a strong password that you're not using elsewhere"
                    icon="vpn_key"
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '450px', flexWrap: 'wrap' }}>
                        {
                            formData.map((field, index) => <FormControl 
                                field={field}
                                formName="Password"
                                key={index}
                                value={this.props.values[field.name]}
                                error={!!this.props.errors[field.name]}
                                errorText={this.props.errors[field.name]}
                                InputChange={this.props.handleChange}
                            />)
                        }

                        <div style={{ flexBasis: '100%' }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{ margin: 16 }}
                                type="submit"
                            >   
                                Submit
                            </Button>
                        </div>

                    </div>
                </ProfileListItem>
            </Form>
        )
    }
}


export default withFormik(asEntity({storeId : "PasswordForm"})(observer(PasswordForm)))
