import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(),
    phoneNumber : Yup.string().trim().required()
});

export const formData = {
    name : {
        name: "name",
        type: "text",
        size : "small",
        width : "60%",
        placeholder: "Name",
    },
    phoneNumber : {
        name: "phoneNumber",
        type: "text",
        size : "small",
        width : "60%",
        placeholder: "Phone Number",
    },
    tt : {
        name: "abbb",
        type: "text",
        size : "small",
        width : "60%",
        placeholder: "Phone Number",
    },

};