const Seconds = (n) => n * 1000;

const baseURL = !!process.env.PUBLIC_URL 
  ? process.env.PUBLIC_URL
  : "https://admin.portal.ioi-tech.com/";

const apiUrl = !!process.env.REACT_APP_BACKEND_BASE_URL 
  ? process.env.REACT_APP_BACKEND_BASE_URL 
  : `https://api.portal.ioi-tech.com/api/` ;
  
export default {
  baseURL,
  apiUrl,
  timeout: Seconds(100),
  clientToken: "SECRET_TOKEN",
  version: "1.0.0",
  // ...config,
};
