const useStyles = (theme) => ({
    root : {
        textAlign : "center",
        paddingBottom : theme.spacing(3),
        display : "flex",
        
    },
    SubmitButton : {
        margin : theme.spacing(1),
        marginTop : theme.spacing(4),
        
    },
    FormWrapper : {
        boxShadow : theme.shadows[3],
        padding : theme.spacing(1) ,
        paddingRight : theme.spacing(4),
        marginTop : theme.spacing(3),
        borderRadius : 5,
    }
});


export default useStyles;