import { List, Typography, withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { PermissionsNormalizer } from '~/Services/Helpers/Normalizers';
import { styles } from './styles';
import TreeItem from './partials/TreeItem'

class Tree extends Component {
  normalizer = {};
  treeData = {};
  loading = false;

  componentDidMount() {
    if (!!this.props.uri) {
      this.loading = true;
      let { uri } = this.props;
      this.props.entityStore.get({ uri });
    } else {
      let { type, title, permissions } = this.props;
      let normalizer = new PermissionsNormalizer({ type, title, permissions });
      this.treeData = normalizer.normalize();
    }
  }

  entityDidReceived(data) {
    if(!!data){
      this.loading = false;
      let { type, title } = this.props;
      
      let normalizer = new PermissionsNormalizer({ type, title, permissions: data })
      this.treeData  =  normalizer.normalize();
    }
  }

  entityDidCatch(errors) {
    console.log('errors', errors);

  }


  render() {
    const { classes } = this.props;

    if (this.loading) {
      return <Loading />
    }

    return (
      <div className={classes.root}>
        <Typography variant="h6">
          {this.props.title}
        </Typography>

        <List aria-labelledby="tree-subheader">
          {
            !!this.treeData?.children && Object.keys(this.treeData.children).map((app, index) => (
              <TreeItem key={index} appName={app} appPermissions={this.treeData.children[app]} />
            ))
          }
        </List>

      </div>
    );
  }
}

decorate(Tree, {
  treeData: observable.shallow
})
export default withStyles(styles)(asEntity({ storeId: "PermissionTree" })(observer(Tree)));