import { makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  modalWrapper: {
    background: theme.palette.type === "light" ? theme.palette.common.white : "#212121",
    padding: theme.spacing(2),
    minWidth: '600px',
    maxWidth: '750px',
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      minWidth: '400px',
      maxWidth: '450px',
    }
  },
  header: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: "bold",
    color: theme.palette.secondary.main,
    textAlign: "left",
    textTransform: "capitalize",
    paddingLeft: theme.spacing(2),
    marginTop: "20px",
    marginBottom: "10px",
  },
}));

export default useStyles;
