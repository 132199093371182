import { FormControl, IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { Visibility } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';


const useStyles = makeStyles(theme => ({
    Input: {
        width : "100%",
        margin: `${theme.spacing(2)}px`
    }
}))

function Input({ type, editDisabled , editState , customClasses , value , focused , error , size , errorText ,InputChange, width, name, blur ,labelWidth, placeholder }) {
    const defaultClasses = useStyles();
    const classes = !!customClasses ? customClasses : defaultClasses;
    
    
    return (
        <div style={{ flexBasis: !!width ? width : "100%" , minWidth : 200 }}>
            <FormControl className={classes.Input}  variant="outlined" >
                <TextField
                    label={placeholder}
                    variant="outlined"
                    type={type}
                    name={name}
                    size={!!size ? size : 'medium'}
                    onChange={InputChange}
                    error={error}
                    value={value}
                    disabled={!!editDisabled && editState }
                    onBlur={blur}
                    { ...( !!focused ? { focused } : {})}   
                    helperText={!!errorText ? errorText : ''}
                    // labelWidth={!!labelWidth ? labelWidth : 60}
                    // {...({
                    //     endIcon: type === "password" && !icon
                    //         ? <InputAdornment position="end">
                    //             <IconButton
                    //                 aria-label="toggle password visibility"
                    //                 edge="end"
                    //             >
                    //                 <Visibility />
                    //             </IconButton>
                    //         </InputAdornment>
                    //         : <Icon> {icon} </Icon>
                    //     })
                    // }
                
                />
            </FormControl>
        </div>
    )
}

Input.defaultProps = {
    customClasses: null,
  };

Input.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.object.isRequired,
    customClasses: PropTypes.object,
}

export default Input;