import React from 'react'
import { Chip } from '@material-ui/core'


export const columns = [
    { name: "id", label: "Id", options: { filter: false, sort: true, display: false } },
    { name: "name", label: "Name", options: { filter: true, sort: true, } },
    { name: "details", label: "Details", options: { filter: true, } },
    { name: "active", label: "Active", options: { filter: true, sort : true ,  customBodyRender : (value , tableMeta, updateValue) => <Chip label={!!value ? "YES" : "NO"}  /> } },
    { name: "company", label: "Company", options: { filter: true,  customBodyRender : (value , tableMeta, updateValue) => value.name  }},
    { name: "permissionlevel", label: "Permission Level", options: { filter: true, customBodyRender : (value , tableMeta, updateValue) => value == 1 ? "Professional" : "Limited" }},
]; 