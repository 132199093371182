import { Hidden, MenuItem, Select, Typography } from '@material-ui/core';
import React from 'react';
import { toAbsoluteUrl } from '~/Services/Helpers';
import { useAppContext } from '~/Services/Providers';
import useStyles from './styles';


const AuthScreen = (props) => {
    const classes = useStyles();
    const { appContextObj, setAppContext } = useAppContext();
    const { welcomeMsg, children } = props;
    const handleLangChange = (event) => {
        setAppContext((currentContext) =>{ 
            return {
            ...currentContext , 
            direction : event.target.value === "ar" ? "rtl" : "ltr" , 
            locale : event.target.value  
        }})
    }

    return (
        <div className={classes.root} >
            <div className={classes.AuthSide} >
                <div>
                    <img className={classes.Logo} src={toAbsoluteUrl("/logo-light.png")} />
                    <div className={classes.WelcomeMsg} >
                        <h2>{welcomeMsg.text}</h2>
                        <span>{welcomeMsg.subText}</span>
                    </div>
                    <Select className={classes.LangSelect} variant="filled" defaultValue={appContextObj.locale} onChange={handleLangChange}>
                        {
                            Object.keys(appContextObj.langs).map((key,index) =>
                                <MenuItem key={index} selected={key == appContextObj.locale} height="16" value={appContextObj.langs[key].code}>
                                    {appContextObj.langs[key].icon} &nbsp;
                                    {appContextObj.langs[key].text}
                                </MenuItem>
                            )
                        }
                    </Select>
                </div>
            </div>

            <div className={classes.FormWrapper}>
                <Hidden mdUp >
                    <img className={classes.LogoMobile} src={toAbsoluteUrl("/logo-light.png")} />
                </Hidden>
                <div className={classes.HeadTitle}>
                    <Typography color="primary" variant="h4" component="h1" >
                        {props.title}
                    </Typography>
                </div>
                {children}

            </div>
        </div>
    )
}


export default AuthScreen;
