import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(),
    com_ID: Yup.string().trim().required("Company is a required field"),
    active: Yup.bool().required(),
    details: Yup.string().trim(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        name: "",
        com_ID: "",
        active: "",
        details: "",
    },
};
export const formData = [
    { name: "name", type: "text", width: "100%", placeholder: "Name", },
    { name: "details", type: "textarea", maxWords: 50, width: "100%", placeholder: "Details", },
    { name: "com_ID", type: "dropdown", width: "48%", placeholder: "Company", optionsURI: "Company/All" },
    { name: "active", width: "20%", type: "switch", placeholder: "Active", },
];