import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    groups: Yup.array(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        groups: [],
    },
};

export const formData = [
    { name: "groups", type: "dropdown-multiple", width: "100%", placeholder: "User Groups", optionsURI: "Account/AllGroups" },
];