import { validationSchema as ProfileValidationSchema } from './Partials/ProfileForm/data';
import { validationSchema as PasswordValidationSchema } from './Partials/PasswordForm/data';

export const profileFormProps = {
    validationSchema: ProfileValidationSchema,
    formik: {
        onChangeValidate: false,
    },
    initialVals: {
        name : "" ,
        phoneNumber : ""
    },
};

export const passwordFormProps = {
    validationSchema : PasswordValidationSchema,
    formik: {
        onChangeValidate: false,
    },
    initialVals: {
        oldPassword : "" ,
        password : "",
        passwordConfirm : "",
    },
};