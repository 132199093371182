import React from 'react'
import { Button, Icon, CircularProgress } from '@material-ui/core'



function ActionButton({ title, loading, icon ,customClass , onClick }) {
    return (
        <Button
            variant="outlined"
            color="secondary"
            className={customClass}
            endIcon={loading ? <CircularProgress color="light" size={15} /> : <Icon > {icon} </Icon>}
            type="submit"
            disabled={loading}
            onClick={onClick}
        >
            {title}
        </Button>
    )
}

export default ActionButton
