import React, { useState } from 'react'
import { List, Collapse, ListItem, ListItemIcon, Icon, ListItemText } from '@material-ui/core';
import { Edit, Close } from '@material-ui/icons'



function ProfileListItem({ children, title, value, secondaryTitle, icon , open , handleTabClick }) {
    return (
        <>
            <ListItem button onClick={() => handleTabClick(title)}>
                {
                    icon && <ListItemIcon><Icon> {icon} </Icon> </ListItemIcon>
                }
                <ListItemText primary={
                    <div style={{ display: 'flex', maxWidth: 450, justifyContent: 'space-between' }}>
                        <span style={{ fontWeight: 500, flex: .5 }}>
                            {title}
                        </span>
                        <span style={{ flex: .5, textAlign: 'left', opacity: .7 }} > {value} </span>
                    </div>
                }
                    secondary={secondaryTitle ? secondaryTitle : ''}
                />
                {open ? <Close color="error" /> : <Edit />}
                
            </ListItem>


            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {children}
                </List>
            </Collapse>
        </>

    )
}

export default ProfileListItem
