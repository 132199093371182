import { Breadcrumbs, Typography, Button } from '@material-ui/core'
import { ArrowBack  } from '@material-ui/icons'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { UpperCaseFirst } from '~/Services/Helpers'
import useStyles from './styles'

const SubHeader = () => {
    const classes = useStyles();
    const history = useHistory();
    const locationArr = history.location.pathname.split('/');

    return (
        <div style={{ display : 'flex'}}>
            <Button
                startIcon={<ArrowBack />}
                onClick={() => history.goBack()}
                style={{marginRight : 3}}
            />
            
            <Breadcrumbs maxItems={4} aria-label="breadcrumb">
                <Typography variant="h5">
                  {UpperCaseFirst(locationArr[1])}  
                </Typography>
                
                {
                    locationArr.length > 2 && locationArr.map((path , index) => {
                        if (index > 1){
                           return (
                            <Link color="inherit" to="/profile" key={index} className={classes.breadCrumb}>
                                {UpperCaseFirst(path)}
                            </Link>
                            )
                        }
                    })
                }
                
         
            
            </Breadcrumbs>
        </div>
    )
}

export default SubHeader
