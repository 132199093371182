import React from 'react'
import { FormattedMessage } from 'react-intl'
import { PhoneNumInput, Input, Switch , TextArea , Dropdown , DropdownMultiple } from '../';

function FormControl({ formName,editState ,  field, error, errorText, editValue ,  value, InputChange, SetFieldValue , blur }) {
    const fieldProps = {
        ...field,
        value,
        error,
        errorText,
        blur,
        editState,
        editValue,
        placeholder: <FormattedMessage id={`${formName}.Form.${field.name}`} />
    } 

    switch (field.type) {
        case "phone":
            return <PhoneNumInput {...fieldProps}
                InputChange={SetFieldValue}
            />
        case "switch":
            return <Switch {...fieldProps}
                InputChange={SetFieldValue}

            />
        case "textarea":
            return <TextArea {...fieldProps} 
                InputChange={SetFieldValue}
            />
        case "dropdown":
            return <Dropdown {...fieldProps} 
                InputChange={SetFieldValue}
            />
        case "dropdown-multiple":
            return <DropdownMultiple {...fieldProps} 
                InputChange={SetFieldValue}
            />

        default:
            return <Input {...fieldProps}
                InputChange={InputChange}
            />
    }
}

export default FormControl
