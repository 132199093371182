import React from 'react'
import PhoneInput from 'react-phone-input-2';
import PropTypes from 'prop-types';
import 'react-phone-input-2/lib/material.css';
import useStyles from './styles';

function PhoneNumInput({ name, InputChange, width, value, placeholder, size , blur }) {
    const classes = useStyles();

    const handleChange = phoneNumber => {
        InputChange(name, phoneNumber);
    }

    return (
        <div className={classes.root} style={{ flexBasis: !!width ? width :'100%'  }}>
            <PhoneInput
                country={'sa'}
                value={value}
                onBlur={blur}
                placeholder={placeholder}
                onChange={handleChange}
                {...(size === "small" ? { inputStyle: { height: 40 } } : {} )
                } inputProps={{
                    name: name,
                }}
            />
        </div>
    )
}

PhoneNumInput.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
}

export default PhoneNumInput;
