const styles = theme => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,

    },
    Show: {
        width: "100%",
        color: theme.palette.text.primary,

    },
    Edit: {
        width: "100%"
    },
    ShowHeader: {
        display: "flex",
        justifyContent: "space-between",
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        backgroundColor: theme.palette.type === "light" ? "#fff" : "#212121",

    },
    ListItemText: {
        minWidth: "150px",
        display: "inline-block"
    },
    InfoPanel: {
        boxShadow: theme.shadows[3],
        borderRadius: 5,
        paddingBottom: 0,

    },

})


export default styles;