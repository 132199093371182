import { Button, withStyles } from '@material-ui/core';
import { AddOutlined } from '@material-ui/icons';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import MUIDataTable from "mui-datatables";
import React from 'react';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { styles } from './styles';
import CustomToolbar from '~/Components/Tables/EnhancedTable/Partials/Toolbar';
import { NotificationContext } from '~/Services/Providers'
import { mixed } from 'yup';
import { columns } from './data'

class BrowseCompanies extends React.Component {
  static contextType = NotificationContext;
  tableData = [];
  loading = true;
  deletedDataBackup = [];

  componentDidMount() {
    this.props.entityStore.get();
  }

  entityDidReceived(data) {
    if (!!data) {
      this.tableData = data;
      this.loading = false;
    }
  };

  entityDidCatch(error) {
    this.loading = false;
    const { setNotification } = this.context;
    setNotification((currState) => ({
      ...currState,
      open: true,
      severity: 'success',
      message: error.title
    }
    ))
    console.log("error", error)
  }

  entityDidDelete() {
    const { setNotification } = this.context;
    setNotification((currState) => ({
      ...currState,
      open: true,
      severity: 'success',
      message: 'Row/s was deleted successfully'
    }
    ))
  }

  deleteAction = (selectedRows, displayData) => {
    let companyIds = []

    Object.keys(selectedRows.lookup).map(dataIndex => {
      companyIds.push(displayData[dataIndex].data[0]);
    })

    this.deletedDataBackup = this.tableData;
    this.tableData = this.tableData.filter(company => !companyIds.includes(company.id))
    companyIds.map(id => {
      this.props.entityStore.delete({ id });
    })
  }

  render() {
    const { classes } = this.props;
    const options = {
      filterType: 'dropdown',
      onRowClick: (company) => this.props.history.push(`/administration/company/${company[0]}`), // {user} is an array of the selected user info  user[1] is the user email
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) =>
      (<CustomToolbar
        deleteAction={this.deleteAction}
        selectedRows={selectedRows}
        displayData={displayData}
        setSelectedRows={setSelectedRows}
      />),
      setRowProps: () => ({ style: { cursor: 'pointer' } })
    };


    if (this.loading) {
      return <Loading />
    }


    return (
      <div className={classes.AddButton}>
        <Button
          onClick={() => this.props.history.push('/administration/company/add')}
          style={{ margin: 15 }}
          color="secondary"
          variant="contained"
          endIcon={<AddOutlined />}
        >
          Add Company
        </Button>

        <div className={classes.table}>
          <MUIDataTable
            title={"Companies"}
            data={this.tableData}
            columns={columns}
            options={options}
          />

        </div>
      </div>
    )
  }
}

decorate(BrowseCompanies, {
  tableData: observable.shallow,
  loading: observable,
  deletedDataBackup: observable,
})

export default withStyles(styles)(asEntity({ storeId: 'BrowseCompanies' })(observer(BrowseCompanies)));