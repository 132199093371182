import React from 'react'
import { Collapse , ListItem , ListItemText , List } from '@material-ui/core'
import { ExpandLess , ExpandMore } from '@material-ui/icons'
import useStyles from './styles'

const TreeItem = ({ appName, appPermissions }) => {
    const [open, setOpen] = React.useState(false);
        const classes = useStyles();

        const handleClick = () => {
            setOpen(!open);
        };

    return (
        <>
            <ListItem button onClick={handleClick}>
                <ListItemText primary={appName} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        Object.keys(appPermissions).map(index => (
                            <ListItem className={classes.NestedItem} button>
                                <ListItemText primary={appPermissions[index]} />
                            </ListItem>

                        ))
                    }
                </List>
            </Collapse>

        </>
    )
}


export default TreeItem;