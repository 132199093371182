import { Icon, ListItem, ListItemIcon, ListItemText , makeStyles } from '@material-ui/core'
import React from 'react'


const useStyles = makeStyles(theme => ({ 
    Icon : { 
        fontSize : 17 , 
    },
    text : {
        fontSize : 17
    }
}))
const AsideItem = ({ icon, name, active , handleClick }) => {
    const classes =  useStyles();

    return (
        <ListItem  selected={active} onClick={handleClick} button>
            <ListItemIcon style={{ minWidth : 30}} >
                <Icon className={classes.Icon} > {icon} </Icon>
            </ListItemIcon>
            <ListItemText className={classes.text} primary={name} />
        </ListItem>
    )
}

export default AsideItem
