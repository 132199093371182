import { IconButton, List, ListItem, ListItemText, Tooltip, Typography, withStyles, Chip, Divider } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import Tree from '~/Components/Tree';
import { asEntity } from '~/Hoc';
import UserForm from '../Partials/UserForm';
import styles from './styles';
import GroupsModal from './Partials/GroupsModal';

class EditUser extends React.Component {
    view = "show";
    email = this.props.match?.params?.email ? this.props.match.params.email : '';
    data = {};
    loading = false;
    usersCompaniesPermissions = [];
    isGroupsModalOpen = false;

    componentDidMount() {
        if (!!this.email) {
            this.loading = true;
            this.props.entityStore.get(this.email)
        }
    }

    updateGroups = () => {
        this.loading = true;
        this.props.entityStore.get(this.email)
    }

    setView = (view) => {
        this.view = view;
    }

    handleGroupsModalClose = () => {
        console.log('done')
        this.isGroupsModalOpen = false;
    }

    handleGroupsModalOpen = () => {
        this.isGroupsModalOpen = true;
    }

    entityDidReceived(data) {
        if (!!data) {
            this.loading = false;
            let { name, email, phoneNumber, sapName, isSapEnabled, permissionLevel, active } = data.user;
            this.usersCompaniesPermissions = data.userPermissions;
            this.data = {
                Name: name,
                Email: email,
                "Phone Number": phoneNumber,
                // "SAP Name": sapName,
                "Is SAP Enabled": <Chip label={!!isSapEnabled ? "YES" : "NO"} />,
                "Permission Level": permissionLevel == 1 ? "Professional" : "Limited",
                Active: <Chip label={!!active ? "YES" : "NO"} />
            }
        }
    }


    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.root}>
                {
                    this.view === "show" &&
                    <div className={classes.Show}>
                        <div className={classes.ShowHeader}>
                            <Typography variant="h6">
                                User Info.
                            </Typography>
                            <Tooltip className={classes.EditButton} title="Edit">
                                <IconButton aria-label="Edit" onClick={() => this.setView("edit")}>
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                        </div>

                        <List className={classes.InfoPanel}>
                            {
                                !!this.data &&
                                Object.keys(this.data).map((key, index) =>
                                    <React.Fragment key={index}>
                                        <ListItem key={index} button>
                                            <ListItemText primary={
                                                <>
                                                    <span className={classes.ListItemText}> <strong> {key} </strong>  </span>
                                                    <span>  {this.data[key]} </span>
                                                </>
                                            } />
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                )
                            }
                        </List>

                        <div className={classes.ShowHeader}>
                            <Typography variant="h6">
                                User Groups
                            </Typography>
                            <Tooltip className={classes.EditButton} title="Edit User Groups">
                                <IconButton aria-label="Edit User Groups" onClick={this.handleGroupsModalOpen}>
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <GroupsModal
                            open={this.isGroupsModalOpen}
                            handleClose={this.handleGroupsModalClose}
                            updateGroups={this.updateGroups}
                            email={this.email}
                        />
                        {
                            !!this.usersCompaniesPermissions?.comLists &&
                            this.usersCompaniesPermissions.comLists.map(company =>
                                <Tree
                                    title={company.groupName}
                                    permissions={company.permissions}
                                    type="user"
                                />)
                        }

                    </div>
                }
                {
                    this.view === "edit" && <div className={classes.Edit}>
                        <UserForm {...this.props} setView={this.setView} />
                    </div>
                }
            </div>
        )
    }
}

decorate(EditUser, {
    view: observable,
    isGroupsModalOpen: observable,
})


export default withStyles(styles)(asEntity({ storeId: "UserView" })(observer(EditUser)));
