import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required("email is a required field")
    .email("please enter a valid email"),
  password: Yup.string().trim().required(),
});

export const formData = [
  {
    name: "email",
    type: "email",
    labelWidth: 40,
    icon:"email" ,
    placeholder: "Email",
  },
  {
    name: "password",
    type: "password",
    labelWidth: 70,
    placeholder: "Password",
  },
];