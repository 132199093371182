import { Button, Icon, Menu, MenuItem } from '@material-ui/core';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useAppContext } from '~/Services/Providers';
import { setStorage } from '~/Services/Helpers';

let data = observable({
    isOpened: false
})

function LangSelector() {
    const { appContextObj, setAppContext } = useAppContext();
    const [AppLang, setAppLang] = useState({ code: appContextObj.locale, text: appContextObj.locale === "en" ? "English" : " العربية " });

    const handleOpen = (event) => {
        data.isOpened = event.currentTarget;
    }

    const handleClose = (event) => {
        data.isOpened = false;
    }
    const handleLangChange = (value) => {
        setAppLang({
            code: value.code,
            text: value.text
        });
        setAppContext((currentContext) => {
            return {
                ...currentContext,
                direction: value.code === "ar" ? "rtl" : "ltr",
                locale: value.code
            }
        });
        setStorage('locale', value.code);
    }

    return (
        <>
            <div>
                <Button
                    aria-controls="lang-menu"
                    aria-haspopup="true"
                    onClick={handleOpen}
                    style={{ color: 'white' }}
                    // startIcon={<Icon>translate</Icon>}
                    endIcon={appContextObj.locale == "en" ? <Icon> keyboard_arrow_down </Icon> : <Icon>translate</Icon>}
                    startIcon={appContextObj.locale == "ar" ? <Icon> keyboard_arrow_down </Icon> : <Icon>translate</Icon>}
                >
                    {AppLang.text}
                </Button>
                <Menu
                    id="lang-menu"
                    anchorEl={data.isOpened}
                    keepMounted
                    open={Boolean(data.isOpened)}
                    onClose={handleClose}
                    variant="selectedMenu"
                    onChange={handleLangChange}

                >
                    {
                        Object.keys(appContextObj.langs).map((langKey, index) => (
                            <MenuItem
                                key={index}
                                selected={AppLang.code === appContextObj.langs[langKey].code}
                                onClick={() => {
                                    handleClose()
                                    handleLangChange(appContextObj.langs[langKey])
                                }}
                            >
                                {appContextObj.langs[langKey].icon} &nbsp;
                                {appContextObj.langs[langKey].text}
                            </MenuItem>

                        ))
                    }
                </Menu>
            </div>
        </>
    )

}


export default observer(LangSelector);
