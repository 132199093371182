import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    permissions: Yup.array(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        permissions: [],
    },
};

export const formData = [
    { name: "permissions", type: "dropdown-multiple", width: "100%", placeholder: "permissions", optionsURI: "Account/Permissions" },
];