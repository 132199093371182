import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    permissions: Yup.array(),
    EnglishLabel: Yup.string().trim().required(),
    ArabicLabel: Yup.string().trim().required(),
    Slug: Yup.string().trim().required(),
    icon: Yup.string().trim().required(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        permissions: [],
        EnglishLabel: '',
        ArabicLabel: '',
        Slug: '',
        icon: '',
    },
};

export let formData = [
    { name: "EnglishLabel", type: "text", width: "48%", placeholder: "English Label" },
    { name: "ArabicLabel", type: "text", width: "48%", placeholder: "Arabic Label" },
    { name: "icon", type: "text", width: "48%", placeholder: "icon" },
    { name: "permissions", type: "dropdown-multiple", width: "100%", placeholder: "Permissions", optionsURI: "Account/Permissions" },
];