import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { StorageKeys } from '~/Configs/Storage';
import { getStorage } from '~/Services/Helpers';

function PrivateRoute(props) {
    return (
        getStorage(StorageKeys.token)  
            ? <Route {...props} />
            : <Redirect to="/login" exact />
    )
}


export default PrivateRoute;