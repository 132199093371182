
export default {
    active: '',
    data: {
        'Administration': {
            icon: 'verified_user_two_tone',
            authorizedRoles: [],
            activeItem: '/administration/users',
            sections: [
                { label: 'Users', icon: 'group', path: '/administration/users' },
                { label: 'Company', icon: 'business', path: '/administration/companies' },
                { label: 'Group', icon: 'supervised_user_circle', path: '/administration/groups' },
                { label: 'Menu', icon: 'menu', path: '/administration/menu' },
            ],
        },
    },
};
