import { put, call } from "redux-saga/effects";
import get from "lodash/get";
import api from "../../Services/Api";
import { StorageKeys } from "../../Configs/Storage";
import EntityActions from "../Actions/Entity";
import AccountActions from "../Actions/Account";
import { getStorage } from '../../Services/Helpers';


export default {
  *get({ id, data }) {
    try {
      if (id === "Profile") {
        const token = getStorage(StorageKeys.token)
        if (token) {
          const res = yield call(
            api.account.get,
            {},
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, "data.message.en", " ");
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }

      if (id === "BrowseUsers") {
        const token = getStorage(StorageKeys.token)
        if (token) {
          const res = yield call(
            api.Users.get,
            {},
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, "data.message.en", " ");
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }

      if (id === "BrowseCompanies") {
        const token = getStorage(StorageKeys.token)
        if (token) {
          const res = yield call(
            api.Companies.get,
            {},
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, "data.message.en", " ");
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === "BrowseGroups") {
        const token = getStorage(StorageKeys.token)
        if (token) {
          const res = yield call(
            api.Groups.get,
            {},
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, "data.message.en", " ");
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }

      if (id === "UserForm") {
        const token = getStorage(StorageKeys.token)

        if (token) {
          const res = yield call(
            api.Users.getUserToken, { data },
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, "data.message.en", " ");
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }

      if (id === "menu") {
        const token = getStorage(StorageKeys.token)

        if (token) {
          const res = yield call(
            api.Menu.get, {},
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, "data.message.en", " ");
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }

      if (id === "UserView") {
        const token = getStorage(StorageKeys.token)

        if (token) {
          const res1 = yield call(api.Users.getUserToken, { data }, { headers: { Authorization: "Bearer " + token, }, });
          const res2 = yield call(api.Users.getUserPermissions, { data }, { headers: { Authorization: "Bearer " + token, }, });

          if (res1.ok && res2.ok) {
            yield put(EntityActions.getSucceeded(id, { user: res1.data, userPermissions: res2.data }));
          } else {
            const error1 = get(res1, "data.message.en", " ");
            const error2 = get(res2, "data.message.en", " ");
            yield put(EntityActions.getFailed(id, { error1, error2 }));
          }
        }
      }

      if (id === "CompanyForm") {
        const token = getStorage(StorageKeys.token)
        if (token) {

          const res1 = yield call(api.Companies.getCompany, { data }, { headers: { Authorization: "Bearer " + token, }, });
          const res2 = yield call(api.Users.getCompanyUsers, { data }, { headers: { Authorization: "Bearer " + token, }, });

          if (res1.ok && res2.ok) {
            yield put(EntityActions.getSucceeded(id, { company: res1.data, users: res2.data }));
          } else {
            const error = get(res1, "data.message.en", " ");
            yield put(EntityActions.getFailed(id, error));
          }

        }
      }

      if (id === "GenericDropdown") {
        const token = getStorage(StorageKeys.token)
        if (token) {

          const res = yield call(
            api.GenericHandlers.get,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, "data.message.en", " ");
            yield put(EntityActions.getFailed(id, error));
          }

        }
      }

      if (id === "GenericDropdownMultiple") {
        const token = getStorage(StorageKeys.token)
        if (token) {

          const res = yield call(
            api.GenericHandlers.get,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, "data.message.en", " ");
            yield put(EntityActions.getFailed(id, error));
          }

        }
      }

      if (id === "GroupForm") {
        const token = getStorage(StorageKeys.token)
        if (token) {

          const res = yield call(
            api.Groups.getGroup,
            { data },
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, "data.message.en", " ");
            yield put(EntityActions.getFailed(id, error));
          }

        }
      }

      if (id === "UserGroupsForm") {
        const token = getStorage(StorageKeys.token)
        if (token) {
          const res = yield call(api.Groups.getUserGroups, data, { headers: { Authorization: "Bearer " + token, }, });

          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, "data.message.en", " ");
            yield put(EntityActions.getFailed(id, error));
          }

        }
      }

      if (id === "PermissionForm") {
        const token = getStorage(StorageKeys.token)
        if (token) {
          const res = yield call(api.Groups.getGroupPermissions, data, { headers: { Authorization: "Bearer " + token, }, });

          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, "data.message.en", " ");
            yield put(EntityActions.getFailed(id, error));
          }

        }
      }

      if (id === "PermissionTree") {
        const token = getStorage(StorageKeys.token)
        if (token) {

          const res = yield call(
            api.GenericHandlers.get,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, "data.message.en", " ");
            yield put(EntityActions.getSucceeded(id, error));
          }

        }
      }

    } catch (errors) {
      console.log("errors", errors);
      yield put(EntityActions.postFailed(id, "errorConnection"));
    }
  },
  *post({ id, data }) {
    try {
      if (id === "login") {
        const res = yield call(api.account.login, data);
        if (res.ok) {
          const { token } = res.data;
          yield put(AccountActions.setToken(token));
          yield put(EntityActions.postSucceeded(id, res.data));
        } else {
          // const error = get(res, "data.message", " ");
          yield put(EntityActions.postFailed(id, res.data));
        }
      }

      if (id === "UserForm") {
        const token = getStorage(StorageKeys.token)
        if (token) {
          const res = yield call(
            api.Users.addUser,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }

      if (id === "menu") {
        const token = getStorage(StorageKeys.token)
        if (token) {
          const res = yield call(
            api.Menu.addMenuObj,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }

      if (id === "CompanyForm") {
        const token = getStorage(StorageKeys.token)
        if (token) {
          const res = yield call(
            api.Companies.addCompany,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }

      if (id === "GroupForm") {
        const token = getStorage(StorageKeys.token)
        if (token) {
          const res = yield call(
            api.Groups.addGroup,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }

      if (id === "UserGroupsForm") {
        const token = getStorage(StorageKeys.token)
        if (token) {
          const res = yield call(api.Groups.updateUserGroups, data, { headers: { Authorization: "Bearer " + token, }, });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            const error = get(res, "data.message.en", " ");
            yield put(EntityActions.postFailed(id, error));
          }

        }
      }

      if (id === "PermissionForm") {
        const token = getStorage(StorageKeys.token)
        if (token) {
          const res = yield call(api.Groups.updateGroupPermissions, data, { headers: { Authorization: "Bearer " + token, }, });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            const error = get(res, "data.message.en", " ");
            yield put(EntityActions.postFailed(id, error));
          }

        }
      }

    } catch (errors) {
      console.log("errors", errors);
      yield put(EntityActions.postFailed(id, "errorConnection"));
    }
  },

  *put({ id, data }) {
    if (id === "PasswordForm") {
      const token = getStorage(StorageKeys.token)
      if (token) {
        const res = yield call(
          api.account.resetPassword,
          data,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, res.data));
        }
      }
    }
  },

  *patch({ id, data }) {
    if (id === "CompanyForm") {
      const token = getStorage(StorageKeys.token)
      if (token) {
        const res = yield call(
          api.Companies.updateCompany,
          data,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, res.data));
        }
      }
    }

    if (id === "GroupForm") {
      const token = getStorage(StorageKeys.token)
      if (token) {
        const res = yield call(
          api.Groups.updateGroup,
          data,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, res.data));
        }
      }
    }

    if (id === "UserForm") {
      const token = getStorage(StorageKeys.token)
      if (token) {
        const res = yield call(
          api.Users.updateUser,
          data,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, res.data));
        }
      }
    }

  },

  *delete({ id, data }) {

    if (id === "BrowseCompanies") {
      const token = getStorage(StorageKeys.token)
      if (token) {
        const res = yield call(api.Companies.deleteCompany, data, { headers: { Authorization: "Bearer " + token, }, });
        if (res.ok) {
          yield put(EntityActions.deleteSucceeded(id, {}));
        } else {
          const error = get(res, 'data.message', 'Connection Error');
          yield put(EntityActions.deleteFailed(id, error));
        }
      }
    }

    if (id === "BrowseGroups") {
      const token = getStorage(StorageKeys.token)
      if (token) {
        const res = yield call(api.Groups.deleteGroup, data, { headers: { Authorization: "Bearer " + token, }, });
        if (res.ok) {
          yield put(EntityActions.deleteSucceeded(id, {}));
        } else {
          const error = get(res, 'data.message', 'Connection Error');
          yield put(EntityActions.deleteFailed(id, error));
        }
      }
    }

    if (id === "BrowseUsers") {
      const token = getStorage(StorageKeys.token)
      if (token) {
        const res = yield call(api.Users.deleteUser, data, { headers: { Authorization: "Bearer " + token, }, });
        if (res.ok) {
          yield put(EntityActions.deleteSucceeded(id, {}));
        } else {
          const error = get(res, 'data.message', 'Connection Error');
          yield put(EntityActions.deleteFailed(id, error));
        }
      }
    }

  },
};
