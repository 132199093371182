import React, { Component } from 'react';
import { withStyles, Modal, Button, Fade, Backdrop } from '@material-ui/core';
import { observer } from 'mobx-react';
import { asEntity } from '~/Hoc';
import { ActionButton, FormBuilder } from '~/Components/From'
import styles from './styles.js';
import { formData, formikProps } from './data';
import { observable, decorate } from 'mobx'
import { NotificationContext } from '~/Services/Providers';

export class Groups extends Component {
    static contextType = NotificationContext;
    loading = false;
    values = {};

    componentDidMount() {
        this.loading = true;
        let { email } = this.props;
        this.props.entityStore.get({ email })
    }

    handleSubmit = (values, editValues = {}) => {
        this.loading = true;
        let groupIds = [];
        values.groups.forEach(value => groupIds.push(value.id));
        let editData = {
            email: this.props.email,
            userGrops: groupIds
        }
        this.props.entityStore.post(editData);
    }

    entityDidReceived(data) {
        if(!!data){
            this.loading =false;
            this.values = {
                groups : data
            }
        }
    }

    entityDidPosted(data) {
        this.loading = false;
        this.props.handleClose();
        this.props.updateGroups();
        this.notify("Success", "User groups updated successfully");
    }
    

    
    entityDidCatch(errors) {
        this.loading = false;
        let message = !!errors.title ? errors.title : "Something went wrong";
        this.notify("error" , message)
        console.log("my error", errors)
    }

    notify = (severity, message) => {
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity,
            message
        }))
    }

    render() {
        const { open, handleClose, classes } = this.props;
        return (
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className={classes.root}
            >
                <Fade in={open} >
                    <div className={classes.modalWrapper}>
                        <FormBuilder {...formikProps}
                            formData={formData}
                            formName="UserGroups"
                            editValues={this.values}
                            submitAction={this.handleSubmit}
                            customStyle={{ flexBasis: "100%" }}
                        >
                            <Button variant="outlined"
                                color="secondary"
                                className={classes.SubmitButton}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>

                            <ActionButton customClass={classes.SubmitButton} title="Save"
                                icon='arrow_forward'
                                loading={this.loading}
                            />
                        </FormBuilder>
                    </div>
                </Fade>
            </Modal>
        )
    }
}

decorate(Groups, {
    loading: observable,
})

export default withStyles(styles)(asEntity({ storeId: "UserGroupsForm" })(observer(Groups)));
