export const styles = theme => ({
    root : {
        boxShadow : theme.shadows[3],
        padding : theme.spacing(2),
        borderRadius : 5
    },
    Tree: {
        height: 110,
        flexGrow: 1,
    },
})