import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    AddButton: {
        float: 'right',
    },
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.type == "dark" ? "#FFF" : "#000"
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}))

export default useStyles;