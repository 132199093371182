import React from 'react'
import { Chip } from '@material-ui/core'



export const columns = [
    { name: "name", label: "Name", options: { filter: false, sort: true, } },
    { name: "email", label: "Email", options: { filter: true, sort: true, } },
    { name: "phoneNumber", label: "Phone Number", options: { filter: false, sort: true, } },
    { name: "userType", label: "User Type", options: { filter: true, sort: true,  customBodyRender : (value , tableMeta, updateValue) => value == 1 ? "Professional" : "Limited"  }},
    { name: "active", label: "Active", options: { filter: true, customBodyRender : (value , tableMeta, updateValue) => <Chip label={!!value ? "YES" : "NO"}  />  }},
  ]