import { withStyles } from '@material-ui/core/styles';
import { decorate, observable } from 'mobx';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import Aside from './Partials/Aside';
import Header from './Partials/Header';
import SubHeader from './Partials/SubHeader';
import styles from './styles';

class Layout extends React.Component {
  open = false;

  handleDrawerToggle = (openVal) => {
    this.open = openVal;
  };

  render() {
    const { classes , children } = this.props;
    return (
      <div className={classes.root}>
        <Header handleDrawerToggle={this.handleDrawerToggle} drawerOpen={this.open} />
        <Aside handleDrawerToggle={this.handleDrawerToggle} open={this.open} />

        <main onClick={() => this.handleDrawerToggle(false)} 
          className={classNames(classes.content  , !!this.open && classes.drawerOpenContent)}
        >
          <div className={classes.toolbar} />
          <SubHeader />
          {children}
        </main>
        
      </div>
    );
  }
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
};

decorate(Layout , {
  open  : observable
});

export default withStyles(styles, { withTheme: true })(observer(Layout));