import React, { useState  , useEffect} from 'react'
import useStyles from './styles'
import { FormControl, TextField } from '@material-ui/core'

function TextArea({ placeholder, maxWords , editValue ,  error, width, name, minRows, value, blur, InputChange, errorText }) {
    const classes = useStyles();
    const [wordCount, setWordCount] = useState(0)
    const [content, setContent] = useState('');

    const setFormattedContent = text => {
        let words = text.split(' ');

        if (words.filter(Boolean).length > maxWords) {
            setWordCount(maxWords);
         
        } else {
            setWordCount(words.filter(Boolean).length);
            setContent(text);
            InputChange(name , text)  
        }
        
    };


    useEffect(() => {
        let value = !!editValue  ? editValue : content;
        setFormattedContent(value);
        InputChange(name , value)
      }, []);


    return (
        <div className={classes.root} style={{ flexBasis: !!width ? width : "100%"} }> 
            <FormControl
                className={classes.textareaControl}
            >
                <TextField
                    label={placeholder}
                    multiline={true}
                    variant="outlined"
                    rows={!!minRows ? minRows : 7}
                    name={name}
                    value={content}
                    onBlur={blur}
                    error={error}
                    onChange={event => setFormattedContent(event.target.value)}
                    helperText={!!errorText ? errorText 
                        : !!maxWords 
                            ? `${wordCount} / ${maxWords} words`
                            : ''

                    }
                />
            </FormControl>
        </div>
    )
}

export default TextArea
